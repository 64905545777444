@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');


body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  overscroll-behavior: none;
  font-weight: 300;
}

b {
  font-weight: 700;
}

// Styles
@import './styles/Variables.scss';
@import './styles/forms.scss';
@import './styles/bootstrap-customisation/imports.scss';

// Client Styles
@import './client/announcement/announcements.scss';
@import './client/job-level/job-level.scss';
@import './client/job-position/map-job-positions.scss';
@import './client/work-permit/order-permits.scss';

// Country Styles
@import './country/recruiter-output/work-permit-output.scss';
@import './country/requirement/requirement-form.scss';
@import './country/requirement/requirement-list.scss';
@import './country/work-permit/work-permit.scss';

// General Styles
@import './general/components/LoadingSpinner.scss';
@import './general/country-client-select/country-client-select.scss';
@import './general/landing-page/landing-page.scss';
@import './general/manage-countries/manage-countries.scss';
@import './general/navigation/header.scss';
@import './general/navigation/nav-bar.scss';
@import './general/text-editor/RichTextEditor.scss';


label {
  font-size: $label-font-size;
  color: $grey;
}

.gw__section-headings {
  font-weight: $font-weight-extra-bold;
  font-family: 'Open Sans', sans-serif;
}

.gw__add-icon {
  font-size: 1rem;
  margin-right: 0.7rem;
}

.gw__loading-spinner {
  color: $green;
}

.gw__loading-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
}

.MuiCircularProgress-root{
  color: $green !important;
}

.loader {
  &.results {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
