.rtf-container {
  .ProseMirror {
    background-color: #FBFBFB;
    min-height: 250px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    overflow-y: auto;
    padding: 20px;
    font-size: 16px;
    font-family: open sans, sans-serif;

    &:focus {
      outline: none;
      border-color: #007bff;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 20px;
      list-style-type: disc;
    }
  }

  .toolbar {
    margin-top: 10px;
    padding: 8px;
    background-color: #f8f9fa;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    button {
      padding: 6px 12px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      cursor: pointer;
      border-radius: 3px;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #e9ecef;
      }

      &.active {
        background-color: #007bff;
        color: white;
        border-color: #0056b3;
      }
    }
  }

  input[type="text"] {
    padding: 6px 12px;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    margin-right: 5px;
    font-size: 14px;
  }
}
